import React from 'react';
import './App.css';

class App extends React.Component {

  STORAGE_KEY = 'claptrap.text';

  constructor(props) {
    super(props);
    this.state = {text: window.localStorage.getItem(this.STORAGE_KEY)};
  }

  onTextChange(e) {
    this.setState({text: e.target.value});
    window.localStorage.setItem(this.STORAGE_KEY, e.target.value);
  }

  render() {
    var clapped = (this.state.text || '')
      .split(/(?<![\.\!]\s*)\s+/)
      .map(x => x.toUpperCase())
      .join(" \u{1F44F} ")
      .split(/(?<=[\.\!])\s+(?=\S+)/)
      .join(" \u{1F64C} ");
    return (
      <div className="App">
        <div>
          <textarea
            className="App-textarea"
            value={this.state.text}
            onChange={this.onTextChange.bind(this)}
          />
        </div>
        <div className="App-output">
          <p>{clapped}</p>
        </div>
      </div>
    );
  }
}

export default App;
